import { Formik } from "formik";
import Notiflix from "notiflix";
import React, { useRef, useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { signUp } from "../../apis/joinApi";
import { SingleLayout, SingleLayoutTail } from "../../components/Layout/SingleLayout/SingleLayout";
import * as S from "../../components/element/Button/style/ButtonLayout.style";
import { IK } from "../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../utils/objectUtils";
import CountryForm from "./CountryForm";
import DentistForm from "./DentistForm";
import DoctorForm from "./DoctorForm";
import EtcForm from "./EtcForm";
import JoinConfirm from "./JoinConfirm";
import Steps from "./Steps";
import TermsForm from "./TermsForm";

function Join() {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const [JoinFlag, setJoinFlag] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**스텝 선택 */
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  /**리캡챠 설정 */
  const recaptchaRef = useRef();

  const stepList = [
    { id: "country", component: <CountryForm /> }, //국가선택
    { id: "term", component: <TermsForm /> }, //약관동의
    { id: "dentist", component: <DentistForm /> }, //치과정보
    { id: "doctor", component: <DoctorForm /> }, //의사정보
    { id: "etc", component: <EtcForm /> }, // 기타정보
  ];

  /**다음 페이지 이동 */
  const handleNext = (values, validateForm, setTouched, setFieldValue) => {
    let nextIndex = currentStepIndex + 1;
    //외국계정의 경우에만 약관처리 넘기기
    if (values.countryCode !== "01" && currentStepIndex === 0) {
      nextIndex = currentStepIndex + 2;
    }

    //수동으로 유휴성 검사
    validateForm().then((errors) => {
      if (Object.entries(errors).length === 0 && errors.constructor === Object) {
        currentStepIndex === stepList.length - 1 ? handleJoin(values, setFieldValue) : setCurrentStepIndex(nextIndex);
      } else {
        setTouched(errors);
      }
    });
  };

  /**이전 페이지 이동 */
  const handlePrev = (values) => {
    if (values.countryCode !== "01" && currentStepIndex === 2) {
      setCurrentStepIndex(0); // 외국계정으로 뒤로가기시 약관동의 이동 방지
    } else if (currentStepIndex === 0) {
      navigate("/"); // 국가선택시 뒤로가기 로그인화면으로 이동
    } else {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };
  const validationSchemas = [
    //국가선택
    Yup.object().shape({
      countryCode: Yup.string().required(t(IK.country_required)),
    }),
    //약관동의
    Yup.object().shape({
      termsOfUse: Yup.bool()
        .isTrue()
        .when("countryCode", {
          is: (val) => val === "01",
          then: (val) => Yup.bool().isTrue().oneOf([true], t(IK.use_terms_required)),
          otherwise: (val) => Yup.bool(),
        }),
      privacy: Yup.bool()
        .isTrue()
        .when("countryCode", {
          is: (val) => val === "01",
          then: (val) => Yup.bool().isTrue().oneOf([true], t(IK.privacy_required)),
          otherwise: (val) => Yup.bool(),
        }),
      privacyThird: Yup.bool()
        .isTrue()
        .when("countryCode", {
          is: (val) => val === "01",
          then: (val) => Yup.bool().isTrue().oneOf([true], t(IK.privacy_use_required)),
          otherwise: (val) => Yup.bool(),
        }),
    }),
    //치과정보
    Yup.object().shape({
      clinicName: Yup.string().required(t(IK.clinic_name_required)),
      postCode: Yup.string().required(t(IK.postcode_required)),
      address: Yup.string().required(t(IK.address_required)),
      tel: Yup.string().required(t(IK.clinic_number_required)),
    }),
    //의사정보
    Yup.object().shape({
      license: Yup.string().required(t(IK.license_required)).matches(/^\S+$/, t(IK.vaild_space)),
      licenseCheck: Yup.bool().isTrue().oneOf([true], t(IK.license_duplicate_check_required)),
      koreaName: Yup.string().when("countryCode", {
        is: (val) => val === "01",
        then: (val) =>
          Yup.string()
            .matches(/^[가-힣]*$/, t(IK.only_korean))
            .required(t(IK.korea_name_required))
            .max(10, t(IK.name_length_limit_10)),
        otherwise: (val) => Yup.string(),
      }),
      lastName: Yup.string()
        .matches(/^[a-zA-Z]+$/, t(IK.only_engilsh))
        .required(t(IK.english_name_required))
        .max(20, t(IK.name_length_limit)),
      firstName: Yup.string()
        .matches(/^[a-zA-Z]+$/, t(IK.only_engilsh))
        .required(t(IK.english_name_required))
        .max(20, t(IK.name_length_limit)),
      loginId: Yup.string()
        .matches(/^[A-Za-z0-9]+$/, t(IK.id_validate))
        .min(4, t(IK.id_min_validate))
        .required(t(IK.id_required))
        .test("invalid-id", t(IK.id_vaild), (value) => {
          const forbiddenUsernames = ["admin", "master", "root", "superuser"];
          return !forbiddenUsernames.includes(value);
        })
        .required(t(IK.id_required)),
      loginIdCheck: Yup.bool().isTrue().oneOf([true], t(IK.id_duplicate_check_required)),
      loginPw: Yup.string()
        .max(16, t(IK.password_max_validate))
        .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/, t(IK.password_validate))
        .required(t(IK.password_required)),
      reLoginPw: Yup.string()
        .oneOf([Yup.ref("loginPw"), null], t(IK.password_not_match))
        .required(t(IK.password_check_required)),
      phone: Yup.string().required(t(IK.number_required)),
      email: Yup.string()
        .email(t(IK.email_valid))
        .test("email", t(IK.email_valid), (value) => {
          if (value) {
            // 영어 대소문자, 숫자, 하이픈(-)만 허용
            const regex = /^[A-Za-z0-9_-]+@[A-Za-z0-9-]+\.[A-Za-z]{2,}$/;
            return regex.test(value);
          }
          return true;
        })
        .required(t(IK.email_required)),
      emailCheck: Yup.bool().isTrue().oneOf([true], t(IK.email_duplicate_check_required)),
      // businessLicense: Yup.mixed().nullable().required(t(IK.business_registration_required)),
    }),
    //기타정보
    Yup.object().shape({
      staffLoginId: Yup.string()
        .matches(/^[A-Za-z0-9]+$/, t(IK.id_validate))
        .min(4, t(IK.id_min_validate)),
      staffLoginIdCheck: Yup.bool().when("staffLoginId", {
        is: (val) => val && val.trim() !== "",
        then: (val) => Yup.bool().isTrue().oneOf([true], t(IK.id_duplicate_check_required)),
        otherwise: (val) => Yup.bool(),
      }),

      staffLoginPw: Yup.string().when("staffLoginId", {
        is: (val) => val && val.trim() !== "",
        then: (val) =>
          Yup.string()
            .max(16, t(IK.password_max_validate))
            .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/, t(IK.password_validate))
            .required(t(IK.password_required)),
        otherwise: (val) => Yup.string(),
      }),
      staffReLoginPw: Yup.string()
        .oneOf([Yup.ref("staffLoginPw"), null], t(IK.password_not_match))
        .test("staffReLoginPw", t(IK.staff_required), function (value) {
          const staffLoginPw = document.getElementById("staffLoginPw").value;
          const staffReLoginPw = document.getElementById("staffReLoginPw").value;
          const requiredPw = staffLoginPw !== "" && !staffReLoginPw;
          return !requiredPw;
        }),
      staffContact1: Yup.string().when("countryCode", {
        is: (val) => val === "01",
        then: (val) => Yup.string(),
        otherwise: (val) =>
          Yup.string()
            .email(t(IK.email_valid))
            .test("email", t(IK.email_valid), (value) => {
              if (value) {
                const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                return regex.test(value);
              }
              return true;
            }),
      }),
      staffContact2: Yup.string().when("countryCode", {
        is: (val) => val === "01",
        then: (val) => Yup.string(),
        otherwise: (val) =>
          Yup.string()
            .email(t(IK.email_valid))
            .test("email", t(IK.email_valid), (value) => {
              if (value) {
                const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                return regex.test(value);
              }
              return true;
            }),
      }),
      recaptcha: Yup.string().required(t(IK.recaptcha_required)),
      recommender: Yup.string().when("countryCode", {
        is: (val) => val === "01",
        then: (val) => Yup.string().required(t(IK.recommendation_required)),
        otherwise: (val) => Yup.string(),
      }),
    }),
  ];

  const schema = Yup.lazy(() => validationSchemas[currentStepIndex]);

  /**회원가입 등록 */
  const handleJoin = (values, setFieldValue) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    const { firstName, lastName, staffContact1, staffContact2, ...rest } = values;
    const name = `${firstName} ${lastName}`;
    const staffContact = [staffContact1, staffContact2].filter(Boolean);

    signUp(deleteEmptyKey({ ...rest, staffContact, name }), t)
      .then((response) => {
        setJoinFlag(true);
      })
      .catch((err) => {
        const errMessage = err.response?.data?.message;
        recaptchaRef.current.reset();
        setFieldValue("recaptcha", "");

        Notiflix.Report.failure(err.message ? err.message : t(IK.again_message), errMessage ? errMessage : t(IK.again_message), t(IK.confirm), () => {});
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Beforeunload onBeforeunload={() => ""}>
      <SingleLayout>
        {JoinFlag ? (
          <JoinConfirm />
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{
              countryCode: "",
              termsOfUse: false,
              privacy: false,
              privacyThird: false,
              marketing: "",
              license: "",
              licenseCheck: false,
              clinicName: "",
              postCode: "",
              address: "",
              detailAddress: "",
              tel: "",
              firstName: "",
              lastName: "",
              loginId: "",
              loginIdCheck: false,
              loginPw: "",
              reLoginPw: "",
              phone: "",
              email: "",
              emailCheck: false,
              staffLoginId: "",
              staffLoginIdCheck: false,
              staffLoginPw: "",
              staffReLoginPw: "",
              staffContact1: "",
              staffContact2: "",
              recommender: "티에네스",
              logo: "",
              businessLicense: "",
              recaptcha: "",
              koreaName: "",
              businessLicenseNumber: "",
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, validateForm, setTouched, setFieldError, errors }) => (
              <>
                <Steps currentStepIndex={currentStepIndex} values={values} />
                {React.cloneElement(stepList[currentStepIndex]?.component, { values, setFieldValue, setFieldError, errors, recaptchaRef })}
                <SingleLayoutTail>
                  <S.StyledButton as="button" type="button" onClick={() => handlePrev(values)}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" $primary type="button" onClick={() => handleNext(values, validateForm, setTouched, setFieldValue)}>
                    {currentStepIndex === stepList.length - 1 ? t(IK.confirm) : t(IK.next)}
                  </S.StyledButton>
                </SingleLayoutTail>
              </>
            )}
          </Formik>
        )}
      </SingleLayout>
    </Beforeunload>
  );
}

export default Join;
