import Notiflix from "notiflix";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { skipStep, useDeleteStudyItem, useGetStudySummary } from "../../../../../apis/studyApi";
import { userInfo } from "../../../../../atoms/userAtom";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { SummaryTable } from "../../../../../components/element/Summary/SummaryTable";
import * as S from "../../../../../components/element/Summary/style/Summary.style";
import LoadingIndicator from "../../../../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../../../../utils/i18n/keyStore";
import { getStudyFormConfig, studySIItemList } from "../../../../../utils/studyUtils";

function Summary({ next, prev, currentStepType, stepLabel, currentStepList, currentStepIndex, studyInfo, setStudyInfo, setCurrentStepIndex, setIsFinish }) {
  const { t } = useTranslation(["translation"]);
  const { patientId } = useParams();

  /**사용자 정보 가져오기 */
  const user = useRecoilValue(userInfo);
  const countryCode = user.countryCode;

  /**서버에서 데이터 받아와 새로 넣어주기 */
  const { data: studyData, isLoading: isStudyLoading } = useGetStudySummary(patientId, studyInfo.studyId);
  const studyPatientData = studyData?.data;

  /**스캔파일만 업데이트 */
  useEffect(() => {
    if (studyPatientData?.scan?.length > 0) {
      setStudyInfo((prev) => ({ ...prev, scan: studyPatientData.scan }));
    }
  }, [studyPatientData, setStudyInfo]);

  /**처방전 요약까지 작성으로 상태 업데이트 */
  useEffect(() => {
    skipStep({ studyId: studyInfo.studyId, patientId }).then((res) => {
      setIsFinish(true); //성공시 스킵 버튼 활성화
    });
  }, [studyInfo.studyId, patientId, setIsFinish]);

  function getIndexByLocation(location) {
    for (let i = 0; i < currentStepList.length; i++) {
      if (currentStepList[i].label === location) {
        return i;
      }
    }
  }

  /**없어진 처방전 항목 제거 처리 */
  const deleteItem = useDeleteStudyItem();

  /**수정하러 이동하기 */
  const handleClickForUpdate = (e) => {
    const location = e.currentTarget.dataset.location;
    const index = getIndexByLocation(location);
    if (!index)
      return Notiflix.Confirm.show(
        t(IK.summary_error_1),
        t(IK.summary_error_2),
        t(IK.confirm),
        t(IK.cancel),
        () => deleteItem.mutate({ studyId: studyInfo.studyId, studyItem: studySIItemList[location] }),
        () => {}
      );
    else if (index === 2) {
      Notiflix.Confirm.show(
        t(IK.is_edit),
        t(IK.edit_dental_arch_treated),
        t(IK.confirm),
        t(IK.cancel),
        () => {
          setCurrentStepIndex(index);
        },
        () => {}
      );
    } else {
      setCurrentStepIndex(index);
    }
  };

  const config = getStudyFormConfig(currentStepType, countryCode);

  return (
    <>
      {isStudyLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <CardTitle
            title={
              <>
                {studyInfo.patientName}
                <span>({`${t(IK[studyInfo.packages])}`})</span>
              </>
            }
          />
          <S.PrintWrapper>
            <SummaryTable studyInfo={studyPatientData} config={config} handleClickForUpdate={handleClickForUpdate} />
          </S.PrintWrapper>
          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton as="button" type="button" onClick={prev}>
                {t(IK.prev)}
              </S2.StyledButton>
              <S2.StyledButton as="button" $primary type="submit" onClick={next}>
                {t(IK.next)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </>
      )}
    </>
  );
}

export default Summary;
