import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../atoms/userAtom";
import { IK } from "../../utils/i18n/keyStore";
import * as S4 from "../element/Button/style/ButtonLayout.style";
import { LanguageButton } from "../element/Language/Language";
import * as S2 from "../element/Table/style/table.style";
import Modal from "../Modal/Modal";
import Privacy from "../Privacy/Privacy";
import Terms from "../Terms/Terms";
import * as S from "./style/Footer.style";
import TimeZone from "./TimeZone";

function Footer() {
  const { t, i18n } = useTranslation(["translation"]);

  /**언어 설정을 변경한 유저의 값 가져오기 (리코일은 로그아웃시 지워버림)*/
  const commonLang = localStorage.getItem("langInfo");

  /**사용자 정보 가져오기 */
  const user = useRecoilValue(userInfo);
  const countryCode = user.countryCode;

  /**사용자 언어 가져오기 */
  const [langInfo, setLangInfo] = useState(commonLang);

  useEffect(() => {
    setLangInfo(commonLang);
  }, [commonLang, setLangInfo]);

  useEffect(() => {
    i18n.changeLanguage(langInfo);
  }, [langInfo, i18n]);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const [privacyOpen, setPrivacyOpen] = useState(false);
  const openPrivacy = () => setPrivacyOpen(true);
  const closePrivacy = () => setPrivacyOpen(false);

  const [termsOpen, setTermsOpen] = useState(false);
  const openTerms = () => setTermsOpen(true);
  const closeTerms = () => setTermsOpen(false);

  return (
    <>
      <S.FooterBox>
        <S.Copyright>{t(IK.tns_copyright)}</S.Copyright>

        {/* 소셜 링크 */}
        <S.FooterInfo>
          <li>
            <Link to="https://youtube.com/channel/UCCKyRUJ4njFJQij-EBsUbew?si=5tIHSxJstqLbjE3y" target="_blank" title="새창열림" rel="noreferrer noopener">
              <S.FooterSocial className="ri-youtube-fill" />
            </Link>
          </li>
          <li>
            <Link to="https://www.instagram.com/serafin_korea?igsh=OWlmZTcybmdpazl5" target="_blank" title="새창열림" rel="noreferrer noopener">
              <S.FooterSocial className="ri-instagram-fill" />
            </Link>
          </li>
          <li>
            <Link to="https://www.facebook.com/share/1A7mc8JSAX/?mibextid=wwXIfr" target="_blank" title="새창열림" rel="noreferrer noopener">
              <S.FooterSocial className="ri-facebook-box-fill" />
            </Link>
          </li>
          {countryCode === "01" && (
            <>
              <li>
                <button type="button" onClick={openPrivacy}>
                  {t(IK.privacy)}
                </button>
              </li>
              <li>
                <button type="button" onClick={openTerms}>
                  {t(IK.terms_of_use)}
                </button>
              </li>
              <li>
                <button type="button" onClick={openModal}>
                  {t(IK.customer_support)}
                </button>
              </li>
            </>
          )}
          <li className="select-time-wrapper">
            {/* 타임존 선택 */}
            <TimeZone />
          </li>
          <li>
            {/* 언어선택 */}
            <LanguageButton langInfo={langInfo} setLangInfo={setLangInfo} />
          </li>
        </S.FooterInfo>
      </S.FooterBox>

      {/* 개인정보처리방침 */}
      <Modal open={privacyOpen} close={closePrivacy} header={t(IK.privacy)}>
        <Privacy />
      </Modal>

      {/* 이용약관 */}
      <Modal open={termsOpen} close={closeTerms} header={t(IK.terms_of_use)} headerSub={"(2023.07.05 " + t(IK.partial_revision) + ")"}>
        <Terms />
      </Modal>

      {/* 고객지원 */}
      <Modal open={modalOpen} close={closeModal} header={t(IK.customer_support)}>
        <S2.TableType1>
          <colgroup>
            <col width="200px"></col>
            <col width="500px"></col>
            <col width="200px"></col>
            <col width="500px"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>{t(IK.business_name)}</th>
              <td className="align_left">{t(IK.tns)}</td>
              <th>{t(IK.customer)}</th>
              <td className="align_left">{t(IK.company_tel)}</td>
            </tr>
            <tr>
              <th>{t(IK.ceo)}</th>
              <td className="align_left">{t(IK.ceo_name)}</td>
              <th>{t(IK.remote_assistance)}</th>
              <td className="align_left">
                <S4.StyledButton $primary as="a" href="https://www.367.co.kr/" target="_blank" title="새창열림">
                  {t(IK.remote_assistance_link)}
                </S4.StyledButton>
              </td>
            </tr>
            <tr>
              <th>{t(IK.business_number)}</th>
              <td className="align_left">206-87-07733</td>
              <th>{t(IK.email)}</th>
              <td className="align_left">tnscs@tnsmile.kr</td>
            </tr>
            <tr>
              <th>{t(IK.company_address_1)}</th>
              <td className="align_left">{t(IK.company_address_detail_1)}</td>
              <th>{t(IK.company_address_2)}</th>
              <td className="align_left">{t(IK.company_address_detail_2)}</td>
            </tr>
            <tr>
              <th>{t(IK.mail_order_business)}</th>
              <td className="align_left">{t(IK.mail_order_business_detail)}</td>
              <th>{t(IK.fax)}</th>
              <td className="align_left">{t(IK.company_fax)}</td>
            </tr>
          </tbody>
        </S2.TableType1>
      </Modal>
    </>
  );
}

export default Footer;
