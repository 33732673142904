import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useGetDoctorInfo, useUpdateDoctorInfo } from "../../../apis/doctorApi";
import { checkEmail } from "../../../apis/joinApi";
import { userInfo } from "../../../atoms/userAtom";
import { CardInnerLayout, CardTail, CardTitle } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageError, FieldMessageErrorClick, FieldMessageSuccess } from "../../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../../components/element/Form/FormLayout";
import * as S2 from "../../../components/element/Form/style/Form.style";
import { IK } from "../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../utils/objectUtils";
import { AccountTabType } from "../AccountTabType";
import SkeletonAccount from "../SkeletonAccount";

function DoctorInfo() {
  const { t } = useTranslation(["translation"]);

  /**의사 정보 조회 */
  const { data, isLoading } = useGetDoctorInfo();
  const doctorInfo = data?.data;

  const doctorInfoMutation = useUpdateDoctorInfo();

  const [isEmailCheck, setIsEmailCheck] = useState(false);

  const user = useRecoilValue(userInfo);

  const validationSchema = Yup.object().shape({
    loginPw: Yup.string()
      .max(16, t(IK.password_max_validate))
      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/, t(IK.password_validate)),
    //.required(t(IK.password_required)),
    reLoginPw: Yup.string()
      .nullable()
      .oneOf([Yup.ref("loginPw"), null], t(IK.password_not_match)),
    //.required(t(IK.password_check_required)),
    phoneNumber: Yup.string().required(t(IK.number_required)),
    email: Yup.string().email(t(IK.email_valid)).required(t(IK.email_required)),
    emailCheck: Yup.bool().isTrue().oneOf([true], t(IK.email_duplicate_check_required)),
  });

  /**이메일 중복 검사*/
  const handleOnClickEmailCheck = (e, values, setFieldValue, setFieldError) => {
    //통과된 이메일이면 통과
    if (isEmailCheck) return;
    //이메일이 형식에 맞는지 먼저 검사
    if (!(values?.email && /^[a-zA-Z0-9_-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(values.email))) return setFieldError("email", t(IK.email_valid));

    if (values.email) {
      checkEmail({ email: values.email })
        .then(() => {
          setIsEmailCheck(true);
          setFieldValue("emailCheck", true);
        })
        .catch((err) => {
          if (err?.response?.status === 410) return setFieldError("emailCheck", t(IK.duplicate_email));
          if (err?.response?.status === 411) return setFieldError("email", t(IK.email_valid));
          Notiflix.Report.warning(t(IK.unknown_err_1), t(IK.unknown_err_2), t(IK.confirm), () => {});
        });
    }
  };

  /**의사 정보 업데이트 */
  const handleSubmit = (values) => {
    const { emailCheck, ...rest } = values;
    doctorInfoMutation.mutate(deleteEmptyKey({ ...rest }), {
      onSuccess: () => {
        Notiflix.Report.success(t(IK.success_save), "", t(IK.confirm));
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.fail_save), "", t(IK.confirm));
      },
    });
  };

  return (
    <Beforeunload onBeforeunload={() => ""}>
      <CardInnerLayout>
        <AccountTabType now="의사정보" />
        <CardTitle required />
        {isLoading ? (
          <SkeletonAccount />
        ) : (
          <>
            <Formik
              initialValues={{
                loginPw: "",
                reLoginPw: "",
                phoneNumber: doctorInfo.phoneNumber || "",
                email: doctorInfo.email || "",
                emailCheck: true,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnMount={true}
              enableReinitialize={true}
            >
              {({ setFieldValue, values, errors, setFieldError }) => (
                <Form>
                  <S2.FormControlsColumn $large>
                    {/* 면허번호 */}
                    <FormControlsBox title={t(IK.license_number)}>{doctorInfo.license}</FormControlsBox>

                    {/* 이름 */}
                    <FormControlsBox title={t(IK.name)}>
                      {doctorInfo?.name} {user.countryCode === "01" ? ` (${doctorInfo?.koreaName})` : ""}
                    </FormControlsBox>

                    {/* 아이디 */}
                    <FormControlsBox title={t(IK.id)}>{doctorInfo.id}</FormControlsBox>

                    {/* 새 비밀번호 */}
                    <FormControlsBox required title={t(IK.new_password)} example={`ex) serafin2021!`}>
                      <Field as={S2.FormControls} type="password" name="loginPw" maxLength="16" />
                      <FieldMessageErrorClick name="loginPw" />
                    </FormControlsBox>

                    {/* 새 비밀번호 확인 */}
                    <FormControlsBox required title={t(IK.confirm_new_password)}>
                      <Field as={S2.FormControls} type="password" name="reLoginPw" maxLength="16" />
                      <FieldMessageErrorClick name="reLoginPw" />
                    </FormControlsBox>

                    {/* 연락처/핸드폰 */}
                    <FormControlsBox required title={t(IK.contact)}>
                      <Field as={S2.FormControls} type="text" name="phoneNumber" maxLength="13" />
                      <FieldMessageErrorClick name="phoneNumber" />
                    </FormControlsBox>

                    {/* 이메일 */}
                    <FormControlsBox required title={t(IK.email)} example={user.countryCode === "01" ? t(IK.tax_bill_use_mail) : ""}>
                      <S2.FormControlsFlex>
                        <Field
                          as={S2.FormControls}
                          type="email"
                          id="email"
                          name="email"
                          onChange={(e) => {
                            setFieldValue("email", e.currentTarget.value);
                            if (e.currentTarget.value !== doctorInfo.email) {
                              setFieldValue("emailCheck", false);
                              setIsEmailCheck(false);
                            } else {
                              setFieldValue("emailCheck", true);
                            }
                          }}
                        />
                        <S2.FormControlsBtn
                          type="button"
                          onClick={(e) => {
                            handleOnClickEmailCheck(e, values, setFieldValue, setFieldError);
                          }}
                        >
                          {t(IK.duplicate_btn)}
                        </S2.FormControlsBtn>
                      </S2.FormControlsFlex>

                      <FieldMessageErrorClick name="email" />
                      {errors.emailCheck && <FieldMessageError text={errors.emailCheck} />}
                      {isEmailCheck && <FieldMessageSuccess text={t(IK.available_email_message)} />}
                    </FormControlsBox>
                  </S2.FormControlsColumn>

                  <CardTail line>
                    <S.ButtonLtBox>
                      <S.StyledButton as="button" $primary type="submit">
                        {t(IK.save)}
                      </S.StyledButton>
                    </S.ButtonLtBox>
                  </CardTail>
                </Form>
              )}
            </Formik>
          </>
        )}
      </CardInnerLayout>
    </Beforeunload>
  );
}

export default DoctorInfo;
